body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  width: 100%;
}
.content {
  margin-left: 250px!important;
  margin-top: 4.5em!important;
  margin-bottom: 1.5em;
}
@media(max-width: 768px){
  .content {
    width: 100%;
    margin-left: 0px!important;
  }
}
a {
  text-decoration: none;
}
.wrap-2{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis
}
.wrap-1{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis
}
.gradient1 {
    background: linear-gradient(100deg, #004080, #007FFF)!important;
}
.text-gradient1 {
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(100deg, #004080, #007FFF)!important;
}
.shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.main-img1 {
  background-image: url('/public/images/viit1.jpg')!important;
  background-size: cover;
  width: 60%;
  background-position: center center;
}
.main-img2 {
  background-image: url('/public/images/viit2.jpg')!important;
  background-size: cover;
  width: 100%;
  background-position: center center;
}

.gimg {
  width: 33.33%!important;
}
@media(max-width: 768px){
  .gimg {
    width: 100%!important;
  }
}